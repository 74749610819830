import React from "react";
import chevron from "../../Assets/arrow-down-circle.svg";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { motion } from "framer-motion";
const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <img
          className={`ml-auto transition-transform duration-200 ease-out ${
            isEnter && "rotate-180"
          }`}
          src={chevron}
          alt="Chevron"
        />
      </>
    )}
    className="border-b"
    buttonProps={{
      className: ({ isEnter }) =>
        `flex w-full p-4 text-left hover:bg-slate-100 ${
          isEnter && "bg-slate-200"
        }`,
    }}
    contentProps={{
      className: "transition-height duration-200 ease-out",
    }}
    panelProps={{ className: "p-4" }}
  />
);

const Animation = {
  hidden: {
    opacity: 0,
    y: 75,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.4,
      when: "beforeChildren",
    },
  },
};
const Accordions = () => {
  return (
    <motion.div
      variants={Animation}
      initial="hidden"
      whileInView="visible"
      className="mx-2 my-4 mt-16 flex flex-col gap-[15px]"
    >
      <h2 className="text-[30px] sm:text-[40px] text-center font-semibold">
        Frequently Asked Questions
      </h2>
      <div className=" border-t ">
        {/* transitionTimeout` prop should be equal to the transition duration in CSS */}
        <Accordion transition transitionTimeout={200}>
          <AccordionItem
            header="Can I use ClearanceResume.ai on my mobile device?"
            initialEntered
          >
            Yes, ClearanceResume.ai is fully accessible and optimized for use on
            mobile devices. Our platform is designed to ensure that you can
            manage your career transition and resume optimization anytime,
            anywhere, right from the palm of your hand. Whether you're updating
            your resume, searching for new job opportunities, or customizing
            your application with a cover letter, you can do it all seamlessly
            on your smartphone or tablet. Our mobile-friendly interface makes
            navigating the site and utilizing our services convenient and
            straightforward, ensuring that you have the tools you need to
            advance your career, no matter where you are.
          </AccordionItem>

          <AccordionItem header="How can ClearanceResume.ai help me get more interviews? ">
            Landing interviews in the highly competitive, security-cleared job
            market requires more than just a strong resume; it demands a
            strategic approach to applying for roles that align perfectly with
            your unique qualifications, and distinguishing yourself from a pool
            of capable candidates vying for the same positions. Employers
            increasingly rely on AI-driven systems to filter through
            applications, selecting only those that closely match their
            criteria. To secure your spot at the top of this pile—and grab that
            crucial interview opportunity—your resume needs to immediately
            capture attention. The average recruiter spends just 6 seconds on an
            initial resume review, making every moment and detail count.
            ClearanceResume.ai's cutting-edge AI technology is engineered to
            elevate your resume above the competition, enhancing it with
            professionally articulated experiences and a design optimized for
            both human eyes and Applicant Tracking Systems (ATS). <br /> <br />
            To further increase your chances of securing an interview,
            ClearanceResume.ai fine-tunes your resume for the roles you're most
            passionate about with just a few clicks. This tailored approach
            signals to employers your genuine interest and effort, significantly
            boosting your likelihood of receiving a callback. <br /> <br />
            With ClearanceResume.ai, you're not just saving time—you're
            strategically positioning yourself for success in the
            security-cleared and military transition job market, all at no cost
            to you. Let us help you navigate your career transition with
            precision, efficiency, and the competitive edge you need to stand
            out.
          </AccordionItem>

          <AccordionItem header="Can I really get a resume for free?">
            Yes! ClearanceResume.ai is completely free to use. Create a custom
            resume from scratch for completely free. Copy and paste it into the
            document type of your choice for FREE! Or pay a small fee and
            download a fully formatted resume in .docx or .pdf formats. You can
            also subscribe to our monthly Unlimited Plan and download as many
            custom resumes as you need for a low monthly price.
          </AccordionItem>
          <AccordionItem header="Why can’t I just use ChatGPT or another AI Tool to create a resume?">
            While general AI tools like ChatGPT can offer broad assistance in
            resume creation, ClearanceResume.ai stands out by providing
            specialized services tailored specifically for the cleared and
            military communities. Unlike one-size-fits-all AI solutions,
            ClearanceResume.ai possesses a deep understanding of the nuances and
            complexities involved in transitioning from a security clearance or
            military role to the civilian workforce. Our platform is uniquely
            designed to navigate the delicate balance of articulating sensitive
            information and duties in a manner that is both appropriate and
            compelling for civilian employers.
            <br />
            <br />
            Moreover, ClearanceResume.ai goes beyond mere content generation. We
            offer a comprehensive resume optimization service that focuses on
            the specific format preferences and requirements of the industries
            and organizations that value security clearances. This includes not
            just the strategic presentation of your skills and experiences, but
            also ensuring that your resume meets the exacting standards of
            Applicant Tracking Systems (ATS) used by employers in the defense,
            government, and private sectors seeking cleared professionals. By
            leveraging ClearanceResume.ai, candidates benefit from an expertly
            crafted resume that accurately reflects their high-value clearance
            status and military experience, formatted to catch the eye of both
            AI filters and human recruiters alike. Our approach ensures that
            your resume doesn't just get seen—it stands out for all the right
            reasons.
          </AccordionItem>
          <AccordionItem header="How often should I update my resume on ClearanceResume.ai?">
            We recommend updating your resume on ClearanceResume.ai whenever you
            acquire new skills, complete significant projects, or achieve new
            certifications, especially those relevant to your clearance level or
            military experience. Keeping your resume current increases your
            visibility to employers and ensures that you are always ready for
            new opportunities. Including these FAQs on your website can help
            address common concerns and highlight the benefits and features of
            ClearanceResume.ai, making it easier for users to understand and
            engage with your services.
          </AccordionItem>
          <AccordionItem header="What makes ClearanceResume.ai different from other resume-building services?">
            ClearanceResume.ai is uniquely tailored for individuals with
            security clearances and transitioning military members. Unlike
            generic resume-building services, we understand the complexities and
            nuances of the cleared community. Our AI-driven platform is
            optimized to showcase your clearance level, military experience, and
            sensitive duties in a way that's both appropriate for civilian roles
            and attractive to employers seeking your unique skill set.
          </AccordionItem>
          <AccordionItem header="Can ClearanceResume.ai help me if I'm transitioning from a military to a civilian career?">
            Absolutely. ClearanceResume.ai specializes in assisting individuals
            transitioning from military to civilian careers. Our platform is
            designed to translate your military experience, skills, and
            qualifications into terms that resonate with civilian employers,
            highlighting your unique value in a way that is relevant and
            compelling.
          </AccordionItem>
        </Accordion>
      </div>
    </motion.div>
  );
};

export default Accordions;
