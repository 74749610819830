import React from "react";
import { ArrowRight } from 'feather-icons-react/build/IconComponents'
import './Featurepagelayout.css'
import { Link } from "react-router-dom";
function Featurepagelayout(props) {
    return (
      
            <div className="featurepage_box">
                <img src={props.img} alt="WPenguino" />
                <h3>{props.title}</h3>
                <p>{props.description}</p>
                <Link to="/features"><ArrowRight /></Link>
            </div>


    )
}
export default Featurepagelayout