import React from "react";
import "./Featurepage.css";
import Header2 from "../../Components/Header2/Header2";
import image22 from "../../Assets/Images/image22.png";
import image23 from "../../Assets/Images/image23.png";
import Featurepagelayout from "./Featurepagelayout";
import Featurepagedata from "./Featurepagedata";
import Customers from "../Pricingpage/Pricingpage_components/Customer/Customers";
import FAQ from "../Pricingpage/Pricingpage_components/FAQ/FAQ";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function FeaturePage() {
  const PageTransitions = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };

  return (
    <motion.div
      variants={PageTransitions}
      initial="hidden"
      whileInView="visible">
      <Header2 />
      <div className="featurepage_Secondsec">
        <motion.div
          variants={Animation}
          initial="hidden"
          whileInView="visible"
          className="featurepage_Services">
          {Featurepagedata.map((index) => {
            return (
              <Featurepagelayout
                key={index.id}
                img={index.img}
                title={index.title}
                description={index.description}
              />
            );
          })}
        </motion.div>
      </div>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="featurepage_thirdsec">
        <h1>Why choose WPenguino 🎖️</h1>
        <p>Get Start</p>
        <div className="featurepage_imgcontainer">
          <Link to="/pricing">
            <img src={image22} alt="WPenguino" />
          </Link>
          <Link to="/pricing">
            <img src={image23} alt="WPenguino" />
          </Link>
        </div>
      </motion.div>
      <Customers />
      <FAQ />
      <Footer />
    </motion.div>
  );
}
export default FeaturePage;
