import React, { useState } from "react";
import logoDark from "../Assets/logodark.PNG";
import { FaBars, FaX } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
const Header = () => {
  const [offCanvas, setOffCanvas] = useState(false);
  const handleOffCanvas = () => {
    setOffCanvas((prev) => !prev);
  };
  const navigate = useNavigate();
  return (
    <div className="w-full relative text-[14px] flex justify-between py-[12px] px-[30px] gap-[20px] items-center">
      <img
        src={logoDark}
        alt="logo"
        className="w-[150px] cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      />
      <ul className="xl:flex hidden gap-[25px] items-center">
        <a
          href="https://app.clearanceresume.ai/dashboard"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer  font-semibold text-[16px]"
        >
          <button> Create Resume</button>
        </a>
        <a
          href="/pricing"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer  font-semibold text-[16px]"
        >
          <button> Pricing</button>
        </a>
        <a
          href="https://www.stemsolutions.net/"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer  font-semibold text-[16px]"
        >
          <button> Jobs</button>
        </a>
        <a
          href="/contact"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer  font-semibold text-[16px]"
        >
          <button> Contact</button>
        </a>
      </ul>
      <div className="hidden xl:flex items-center">
        <a
          href="https://app.clearanceresume.ai/"
          target="_blank"
          rel="noopener noreferrer"
          className="px-[20px] py-[12px] bg-transparent rounded-md font-medium text-[#064e3b]"
        >
          <button>Login</button>
        </a>

        <a
          href="https://app.clearanceresume.ai/auth/signup"
          target="_blank"
          rel="noopener noreferrer"
          className="px-[20px] py-[12px] bg-[#383838] rounded-md font-medium text-white"
        >
          <button>Sign Up</button>
        </a>
      </div>
      {offCanvas ? (
        <FaX
          onClick={handleOffCanvas}
          className="xl:hidden cursor-pointer font-semibold"
        />
      ) : (
        <FaBars
          onClick={handleOffCanvas}
          className="xl:hidden cursor-pointer font-semibold"
        />
      )}
      {offCanvas ? (
        <div className="absolute z-[999] shadow-2xl w-[60%] sm:w-[30%] flex flex-col xl:hidden text-[#a5a5a5] bg-white sm:right-[30px] right-[20px] top-[100px] sm:top-[80px]">
          <a
            href="https://app.clearanceresume.ai/dashboard"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full p-[10px] border-b border-[#a5a5a5] text-left font-medium text-[#3a415c]"
          >
            <button> Create Resume</button>
          </a>
          <a
            href="/pricing"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full p-[10px] border-b border-[#a5a5a5] text-left font-medium text-[#3a415c]"
          >
            <button> Pricing</button>
          </a>

          <a
            href="https://www.stemsolutions.net/"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full p-[10px] border-b border-[#a5a5a5] text-left font-medium text-[#3a415c]"
          >
            <button>Jobs</button>
          </a>

          <a
            href="/contact"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full p-[10px] border-b border-[#a5a5a5] text-left font-medium text-[#3a415c]"
          >
            <button>Contact</button>
          </a>
          <a
            href="https://app.clearanceresume.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full p-[10px] border-b border-[#a5a5a5] text-left font-medium text-[#3a415c]"
          >
            <button>Login</button>
          </a>
          <a
            href="https://app.clearanceresume.ai/auth/signup"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full p-[10px] border-b border-[#a5a5a5] text-left font-medium bg-[#3a415c] text-white"
          >
            <button>Sign Up</button>
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
