import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import {
  Facebook,
  Youtube,
  Instagram,
  Twitter,
  Linkedin,
  LogIn,
  UserPlus,
} from "feather-icons-react/build/IconComponents";
import image20 from "../../Assets/Images/image20.png";
import logo from "../../Assets/logo.PNG";
import { motion } from "framer-motion";
function Footer() {
  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };
  return (
    <motion.div
      variants={Animation}
      initial="hidden"
      animate="visible"
      className="footer"
    >
      <h2>Ready To Launch 🚀</h2>
      <p>Automate your conversations and boost your marketing strategy</p>
      <div className="footer_btnContainer">
        <Link to="/pricing">
          {" "}
          <button>
            <LogIn />
            Get Start
          </button>
        </Link>
        <Link to="/pricing">
          <button>
            <UserPlus />
            Try for free
          </button>
        </Link>
      </div>
      <div className="footer_main">
        {/* image of the company */}
        <div className="footer_logo">
          <Link to="/">
            <img src={logo} alt="WPenguino" />{" "}
          </Link>
        </div>
        {/* links of the pages in footer */}
        <div className="footer_pages">
          <ul>
            <li>
              <Link to="/#">FEATURES</Link>
            </li>
            <li>
              <Link to="/#">ABOUT US</Link>
            </li>
            <li>
              <Link to="/#">PRICING</Link>
            </li>
            <li>
              <Link to="#">FAQ</Link>
            </li>
            <li>
              <Link to="#">NEWS</Link>
            </li>
          </ul>
        </div>
        <div className="footer_login">
          <Link to="#">
            <Facebook />
          </Link>
          <Link to="#">
            <Youtube />
          </Link>
          <Link to="#">
            <Instagram />
          </Link>
          <Link to="#">
            <Twitter />
          </Link>
          <Link to="#">
            <Linkedin />
          </Link>
        </div>
      </div>
    </motion.div>
  );
}
export default Footer;
