import React from "react";
import "./Pricing.css";
import { CheckCircle, Star } from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function Pricing() {
  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };
  return (
    <motion.div
      variants={Animation}
      initial="hidden"
      animate="visible"
      className="home_pricingsec"
    >
      <h2>
        Click "Sign Up Now" to create
        <br />
        your FREE Resume
      </h2>

      <div className="pricing_container">
        <div className="pricing_box1">
          {/* <p>
              <CheckCircle />
              ENTERPRISE
            </p> */}
          <h2>
            $49.00 /<span>One Time Purchase</span>
          </h2>
          <div className="pricelist">
            <ul>
              <li>Create optimized resumes</li>
              <li>Copy and paste content from site</li>
              <li>Unlimited resume edits</li>
              <li>Save resume as .pdf, .docx</li>
              <li>Download fully formatted Resume</li>
            </ul>
          </div>
          <div className="pricing_btncontainer2">
            <Link to="#">
              {" "}
              <button> SIGN UP NOW </button>
            </Link>
          </div>
        </div>
        <div className="pricing_box2">
          {/* <p>
              <CheckCircle />
              BASIC
            </p> */}
          <h2>Free</h2>
          <div className="pricelist">
            <ul>
              <li>Create optimized resumes</li>

              <li>Copy and paste content from site</li>
              <li>Unlimited resume edits</li>
            </ul>
          </div>
          <div className="pricing_btncontainer1">
            <Link to="#">
              {" "}
              <button> SIGN UP NOW </button>
            </Link>
          </div>
        </div>

        <div className="pricing_box3">
          {/* <p>
              <Star />
              STARTER
            </p> */}
          <h2>
            $39.00 /<span>Month</span>
          </h2>
          <div className="pricelist">
            <ul>
              <li>Create optimized resumes</li>
              <li>Copy and paste content from site</li>
              <li>Unlimited resume edits</li>
              <li>Save resume as .pdf, .docx</li>
              <li>Download fully formatted Resume</li>
              <li>Unlimited Resume Downloads</li>
              <li>Save multiple variations of resume</li>
            </ul>
          </div>
          <div className="pricing_btncontainer3">
            <Link to="#">
              {" "}
              <button>SIGN UP NOW </button>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
export default Pricing;
