import React from "react";
import Header from "../../common/header";
import Footer from "../../common/footer";

const TermsOfServices = () => {
  return (
    <div>
      <Header />
      <div className="w-full flex flex-col gap-[12px] px-[30px] xl:px-[100px] py-[30px]">
        <h1 className="text-[30px] font-semibold">Terms Of Services</h1>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Introduction:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              Welcome to www.clearanceresume.ai. By accessing or using our
              website and services, you agree to be bound by these Terms of
              Service (ToS). If you do not agree with any part of the ToS, you
              must not use this website.
            </li>
            <li>
              The services offered by www.clearanceresume.ai are intended for
              individuals seeking employment and employers within sectors
              requiring security clearances. Our platform utilizes artificial
              intelligence to assist users in creating, optimizing, and
              distributing resumes.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Privacy & Data Protection:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              We are committed to protecting the privacy and security of our
              users' personal information. Our Privacy Policy, which is part of
              these Terms, outlines our practices concerning data collection,
              use, and security.
            </li>
            <li>
              Users must consent to the collection, use, and sharing of personal
              data, including but not limited to employment history, education,
              and security clearance details, in accordance with our Privacy
              Policy.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">User Responsibility:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              Users are responsible for the accuracy and completeness of the
              information they provide on the platform.
            </li>
            <li>
              Users with security clearances must comply with all applicable
              laws and regulations regarding the handling and disclosure of
              classified information.
            </li>
            <li>
              The use of our services for illegal activities, including the
              unauthorized sharing of sensitive or classified information, is
              strictly prohibited.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Intellectual Property:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              All content provided on www.clearanceresume.ai, including text,
              graphics, logos, and software, is the property of the website or
              its content suppliers and protected by international copyright
              laws.
            </li>
            <li>
              The use of any content from the website without prior written
              permission from us is strictly prohibited.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Limitation of Liability:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              www.clearanceresume.ai provides its services "as is" without any
              express or implied warranties. We do not guarantee that our
              services will meet your requirements or be available on an
              uninterrupted basis.
            </li>
            <li>
              We are not liable for any direct, indirect, incidental, or
              consequential damages arising out of the use or inability to use
              our services.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Amendments to the Terms:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              We reserve the right to modify or replace these Terms at any time.
              Any changes will be effective immediately upon posting on the
              website. Your continued use of the website after any changes to
              the Terms constitutes your acceptance of those changes.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Governing Law:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              These Terms are governed by the laws of the jurisdiction in which
              our company is registered, without regard to its conflict of law
              provisions.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Contact Information:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              For any questions or concerns regarding these Terms or the
              services provided, please contact us through the provided contact
              details on our website.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfServices;
