import React from "react";
import Header from "../../common/header";
import Footer from "../../common/footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="w-full flex flex-col gap-[12px] px-[30px] xl:px-[100px] py-[30px]">
        <h1 className="text-[30px] font-semibold">Privacy Policy</h1>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Introduction:</h2>
          <p>
            Welcome to www.clearanceresume.ai. We are committed to protecting
            the privacy and security of your personal information. This Privacy
            Notice explains how we collect, use, store, and protect your
            information when you use our website and services. Information We
            Collect -
          </p>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              Personal Information: This includes information that can be used
              to identify you, such as your name, email address, telephone
              number, employment history, educational background, and details
              regarding your security clearance.
            </li>
            <li>
              Usage Data: Information on how the website and services are
              accessed and used, including your IP address, browser type, and
              usage patterns.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">How We Use Your Information:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              To provide and maintain our services, including using your
              information to optimize your resume and job search.
            </li>
            <li>
              To communicate with you, including sending service-related notices
              and promotional materials.
            </li>
            <li>To improve and personalize your experience on our website.</li>
            <li>
              To comply with legal obligations and enforce our terms and
              conditions.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Sharing Your Information:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              We do not sell your personal information. Information may be
              shared with third-party service providers who assist us in
              operating our website, conducting our business, or serving our
              users, so long as those parties agree to keep this information
              confidential.
            </li>
            <li>
              Information may also be disclosed where required by law or to
              protect the rights, property, or safety of www.clearanceresume.ai,
              our users, or others.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Data Security:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              We implement a variety of security measures to maintain the safety
              of your personal information. However, no method of transmission
              over the Internet or method of electronic storage is 100% secure,
              and we cannot guarantee its absolute security.
            </li>
            <li>
              Sensitive data, such as security clearance information, is handled
              with enhanced security measures and compliance protocols to
              protect against unauthorized access or disclosure.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Data Retention:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              We will retain your personal information only for as long as is
              necessary for the purposes set out in this Privacy Notice, and to
              comply with our legal obligations, resolve disputes, and enforce
              our policies.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Amendments to the Terms:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              We reserve the right to modify or replace these Terms at any time.
              Any changes will be effective immediately upon posting on the
              website. Your continued use of the website after any changes to
              the Terms constitutes your acceptance of those changes.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Your Rights:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              Depending on your jurisdiction, you may have rights regarding your
              personal information, such as the right to access, correct, or
              delete your personal data held by us.
            </li>
            <li>
              To exercise these rights, please contact us using the contact
              information provided on our website.
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col gap-[7px]">
          <h2 className="font-semibold">Changes to this Privacy Notice:</h2>
          <ul className="flex flex-col gap-[5px] list-disc">
            <li>
              We may update our Privacy Notice from time to time. We will notify
              you of any changes by posting the new Privacy Notice on this page.
            </li>
            <li>
              You are advised to review this Privacy Notice periodically for any
              changes. Changes to this Privacy Notice are effective when they
              are posted on this page.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-[5px]">
          <h2 className="font-semibold">Contact Us:</h2>
          <p>
            If you have any questions about this Privacy Notice or our treatment
            of your personal information, please contact us via the contact
            methods provided on our website.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
