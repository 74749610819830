import React, { useEffect, useState } from "react";
import './Customers.css'
import Img1 from '../../../../Assets/Images/customers-removebg-preview.png'
import Img2 from '../../../../Assets/Images/img2.png'
import Img3 from '../../../../Assets/Images/img1.png'
import {motion} from 'framer-motion'
function Customers() {
    const [count, setCount] = useState(0);

    useEffect(() => {
      const timer = setInterval(() => {
        setCount(count + 1);
        if (count >= 2000) {
          clearInterval(timer);
        }
      }, 5);
      return () => {
        clearInterval(timer);
      };
    });

    const Animation = {
      hidden:{
          opacity: 0,
          y:75,
      },
      visible:{
          opacity:1,
          y:0,
          transition:{
              duration: 0.5,
              staggerChildren: 0.4,
              when: 'beforeChildren'
          }
      }
  
  }
    return (
        <motion.div variants={Animation} initial='hidden' whileInView='visible' className="customers_main">
            <div className="customers_box">
                <div className="customers_img"> <img src={Img1}alt="WPenguino" /></div>
                <div className="customers_text"> <h2>1200</h2>
                <p>Active Customers</p>
                </div>


            </div>
            <div className="customers_box">
            <div className="customers_img"> <img src={Img3}alt="WPenguino" /></div>
                <div className="customers_text"><h2>1500</h2>
               <p>Total Customers</p>
                </div>


            </div>
            <div className="customers_box">
            <div className="customers_img"> <img src={Img2}alt="WPenguino" /></div>
                <div className="customers_text">  <h2>600</h2>
                <p>Positive Reviews</p>
                </div>


            </div>
        </motion.div>
    )
}
export default Customers