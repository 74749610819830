import React from "react";
import "./PricingPage.css";
import Pricing from "../Home/Home_Components/Pricing/Pricing";
import Customers from "./Pricingpage_components/Customer/Customers";
import FAQ from "./Pricingpage_components/FAQ/FAQ";
import Footer from "../../common/footer";
import Header2 from "../../Components/Header2/Header2";
import image22 from "../../Assets/Images/image22.png";
import image23 from "../../Assets/Images/image23.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "../../common/header";
function PricingPage() {
  const PageTransitions = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };

  return (
    <motion.div
      variants={PageTransitions}
      initial="hidden"
      whileInView="visible"
      className="pricingpage_main">
      <Header />
      <Pricing />
      {/* <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="pricingpage_second">
        <h1>Why choose WPenguino 🎖️</h1>
        <p>Get Start</p>
        <div className="pricingpage_imgcontainer">
          <Link to="/pricing">
            <img src={image22} alt="WPenguino" />
          </Link>
          <Link to="/pricing">
            <img src={image23} alt="WPenguino" />
          </Link>
        </div>
      </motion.div>

      <Customers />
      <FAQ /> */}
      <Footer />{" "}
    </motion.div>
  );
}
export default PricingPage;
