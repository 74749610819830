import React from "react";
import './FAQ.css'
import { useState } from "react";
import {motion} from 'framer-motion'
function FAQ(){
    const [text, setText] = useState(0)
    function show(index){
        setText(index === text ? null : index)
    }
    const [close,setClose]=useState(true)
    const closeBtn = ()=> {
        setClose(false)
    }

    const Animation = {
        hidden:{
            opacity: 0,
            y:75,
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                duration: 0.5,
                staggerChildren: 0.4,
                when: 'beforeChildren'
            }
        }
    
    }
    
    
    return (<div className="FAQ_main">
        <motion.h2 variants={Animation} initial='hidden' whileInView='visible'>Frequently Asked Questions</motion.h2>
        {/* FAQ portion starts here */}
        <motion.div variants={Animation} initial='hidden' whileInView='visible' className="FAQ_container">
<div onClick={()=>show(1)} className="FAQ_box" id="1">
<p className="FAQ_activetext">How it will take impact for Food & Restaurants business.<span onClick={closeBtn}>{text===1 ? "-" : "+"}</span></p>
{close ? null : false}
{text ===1 ? <p className="FAQ_text">Revolutionize your restaurant operations with WhatsApp-based ordering, meal and add-on recommendations, payments and real-time delivery updates</p> : null}

</div>
<div onClick={()=>show(2)} className="FAQ_box" id="2">
<p className="FAQ_activetext">Financial Services<span>{text===2 ? "-" : "+"}</span></p>
{text ===2 ? <p className="FAQ_text">Boost digital finance adoption with instant balance inquiries, portfolio recommendations, hassle-free claims settlement, cross-sell and more</p> : null}

</div>
<div onClick={()=>show(3)} className="FAQ_box" id="3">
<p  className="FAQ_activetext">Do you offer a 30 day money-back guarantee?<span>{text===3 ? "-" : "+"}</span></p>
{text ===3 ? <p className="FAQ_text">Yes, If you are unhappy with our service, we offer 30 days money-back guarantee on any plan.</p> : null}

</div>
<div onClick={()=>show(4)} className="FAQ_box" id="4">
<p  className="FAQ_activetext">Do I need a credit card to sign up?<span>{text===4 ? "-" : "+"}</span></p>
{text ===4 ? <p className="FAQ_text">No, you only need a email to sign up.</p> : null}

</div>
<div onClick={()=>show(5)} className="FAQ_box" id="5">
<p  className="FAQ_activetext">Why does your business need a Chatbot?<span>{text===5 ? "-" : "+"}</span></p>
{text ===5 ? <p className="FAQ_text">A chatbot is always available to serve your customer 24/7. A chatbot can save you a lot of time and money by automating repetitive tasks like qualifying leads or answering questions of your customers. By offering instatily response to your customer, your business will build trust and boost conversions and sales.</p> : null}

</div>
        </motion.div>
    </div>)
}
export default FAQ