import Home from "./Pages/Home/Home";
import "./App.css";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home2 from "./Pages/Home/Home2";
import FeaturePage from "./Pages/FeaturePage/Featurepage";
import PricingPage from "./Pages/Pricingpage/PricingPage";
import About from "./Pages/About/About";
import Contact from "./Pages/ContactPage/Contact";
import PageNotFound from "./Pages/404Page copy/404Page";
import TermsOfServices from "./Pages/privacy and terms/termsOfServices";
import PrivacyPolicy from "./Pages/privacy and terms/privacyPolicy";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <div className="App">
        <AnimatePresence>
          <Routes location={location} key={location.key}>
            <Route element="">
              <Route key="frontpage" element={<Home2 />} path="/" exact />

              <Route
                key="pricing"
                element={<PricingPage />}
                s
                path="/pricing"
                exact
              />
              <Route
                key="features"
                element={<FeaturePage />}
                path="/features"
                exact
              />
              <Route key="about" element={<About />} path="/about" exact />
              <Route
                key="contact"
                element={<Contact />}
                path="/contact"
                exact
              />
              <Route key="pagenotfound" element={<PageNotFound />} path="/*" />
              <Route
                key="terms-of-services"
                element={<TermsOfServices />}
                path="/terms-of-services"
              />
              <Route
                key="privacy-policy"
                element={<PrivacyPolicy />}
                path="/privacy-policy"
              />
            </Route>
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default App;
