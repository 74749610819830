import React from "react";
import "./404Page.css";
import { Link } from "react-router-dom";
import Header2 from "../../Components/Header2/Header2";
import { motion } from "framer-motion";
import Header from "../../common/header";
function PageNotFound() {
  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };
  const PageTransitions = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <motion.div
      variants={PageTransitions}
      initial="hidden"
      whileInView="visible">
      <Header />

      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="pagenotfound">
        <h1>Oops!</h1>
        <h2>404 - PAGE NOT FOUND</h2>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <Link to="/">
          {" "}
          <button>Go to Homepage</button>
        </Link>
      </motion.div>
    </motion.div>
  );
}
export default PageNotFound;
