import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Command } from "feather-icons-react/build/IconComponents";
import Columns from "feather-icons-react/build/IconComponents/Columns";
import { motion } from "framer-motion";
const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show three slides at once
    slidesToScroll: 3, // Scroll three slides at once
    responsive: [
      {
        breakpoint: 1024, // Adjust the number according to your design
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // Adjust the number according to your design
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      quote:
        "As someone transitioning from military service, ClearanceResume.ai was a game-changer. It understood the nuances of my military experience and translated it into a civilian-friendly format that really resonated with employers. The platform's focus on security clearances meant my unique qualifications were highlighted in a way that truly set me apart from other candidates.",
    },
    {
      quote:
        "Navigating the job market with a security clearance can be challenging, but ClearanceResume.ai made it seamless. The AI's ability to tailor my resume while maintaining the integrity of sensitive information was impressive. It’s a must-use tool for anyone in the cleared community looking to advance their career.",
    },
    {
      quote:
        "Switching careers within the security clearance realm felt daunting until I discovered ClearanceResume.ai. The platform's insightful guidance on emphasizing transferable skills and optimizing my resume for specific roles was invaluable. It provided me with the confidence and tools necessary to successfully pivot my career path.",
    },
    {
      quote:
        "As a recent graduate entering the workforce with a clearance, ClearanceResume.ai was instrumental in helping me showcase my academic achievements and internships in a professional light that appealed to high-security employers. Its ability to match me with roles suited to my clearance level and career aspirations was incredibly beneficial.",
    },
    {
      quote:
        "After upgrading my security clearance, ClearanceResume.ai helped me to revise my resume to reflect this significant change. The platform expertise in the cleared community allowed me to highlight my new clearance status effectively, opening doors to more advanced and higher-paying positions in my field.",
    },
    {
      quote:
        "Returning to the job market after a hiatus can be intimidating, especially with a security clearance. ClearanceResume.ai not only refreshed my resume but also ensured it met current industry standards for cleared positions. Its personalized cover letter service further enhanced my applications, making my re-entry into the workforce smoother and more successful.",
    },
  ];
  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };

  return (
    <motion.div
      variants={Animation}
      initial="hidden"
      whileInView="visible"
      className="bg-[#656f83] text-white py-[6%] overflow-x-hidden"
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-10">
          Trusted by Job Seekers
        </h2>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="outline-none px-2">
              <div className="p-6 rounded-lg border-gray-100 border-[0.5px] text-[14px]  h-[300px]">
                <Columns className="" />
                <p className=" mt-7">{testimonial.quote}</p>
                <p className="mt-4 font-bold">{testimonial.author}</p>
                <p className="text-sm">{testimonial.location}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </motion.div>
  );
};

export default TestimonialSlider;
