import React from "react";
import './Team.css'
import Teamdata from "./Teamdata";
import Teamlayout from "./Teamlayout";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
function Team(){

    const Animation = {
        hidden:{
            opacity: 0,
            y:75,
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                duration: 0.5,
                staggerChildren: 0.4,
                when: 'beforeChildren'
            }
        }
    
    }

    return (
        <motion.div variants={Animation} initial='hidden' whileInView='visible' className="team_main">
            <div className="team_container">
            <div className="team_maintext">
                <h2>Meet with our team</h2>
            </div>
            <div className="team_buttoncontainer">
            <Link to="#"> <button className="button1">ALL TEAM MEMBER</button></Link>
            <Link to="#">  <button className="button2">JOIN OUR TEAM</button></Link>
            </div>
            </div>
            <div className="teamimagecontainer">
            {Teamdata.map((index)=>{
                return <Teamlayout
                key = {index.id}
                img = {index.img}
                name = {index.name}
                des = {index.des}
                />
            })}
            </div>
        </motion.div>
    )
}
export default Team