import React from "react";
import './Teamlayout.css'
import {Facebook, Instagram, Twitter, Linkedin} from 'feather-icons-react/build/IconComponents'
import { Link } from "react-router-dom";
function Teamlayout(props){
    return (
            <div className="team_box">
            <img src={props.img} alt="WPenguino" />
            <h3>{props.name}</h3>
            <p>{props.des}</p>
         
            <div className="sociallinks">
            <Link to="#"><Facebook /></Link>
            <Link to="#"><Twitter /></Link>
            <Link to="#"><Instagram /></Link>
            <Link to="#"><Linkedin /></Link>
            </div>
            </div>
          
    )
}
export default Teamlayout