import React from "react";
import "./Contact.css";
import Header2 from "../../Components/Header2/Header2";
import {
  MapPin,
  PhoneCall,
  Mail,
} from "feather-icons-react/build/IconComponents";
import Footer from "../../common/footer";

import { motion } from "framer-motion";
import Header from "../../common/header";
function Contact() {
  const PageTransitions = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };
  return (
    <motion.div
      variants={PageTransitions}
      initial="hidden"
      whileInView="visible"
    >
      <Header />

      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="contact_details"
      >
        <h2>Reach Out to Us using the Form Below</h2>
      </motion.div>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="contact_boxContainer"
      >
        <div className="contact_box">
          <Mail />
          <h3>Email Us</h3>
          <p>team@clearanceresume.ai</p>
        </div>
      </motion.div>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="contact_secondsec"
      >
        <h1>Send us a Message:</h1>
      </motion.div>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="contact_input"
      >
        <input type="text" className="name" placeholder="Enter Your Name" />
        <input type="email" className="mail" placeholder="Enter Your Mail" />
        <input
          type="number"
          className="number"
          placeholder="Enter Your Number"
        />
        <input type="text" className="subject" placeholder="Subject" />
        <textarea
          className="textarea"
          placeholder="Type your message"
        ></textarea>
        <button>Send Message</button>
      </motion.div>
      <Footer />
    </motion.div>
  );
}
export default Contact;
