import React from "react";
import logo from "../Assets/logo.PNG";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="w-full bg-[#656f83] text-[#f4f0e8] p-[30px] sm:p-[50px] flex flex-col gap-[20px]">
      <div className="w-full flex flex-col xl:flex-row gap-[30px]">
        <div className="w-full xl:w-[60%] flex flex-col gap-[15px]">
          <img src={logo} alt="logo" className="w-[200px]" />
          <div className="flex gap-[12px] items-center">
            <FaInstagram className="cursor-pointer hover:text-white" />
            <FaTiktok className="cursor-pointer hover:text-white" />
            <FaLinkedin className="cursor-pointer hover:text-white" />
            <FaYoutube className="cursor-pointer hover:text-white" />
            <FaFacebook className="cursor-pointer hover:text-white" />
            <FaTwitter className="cursor-pointer hover:text-white" />
          </div>
        </div>
        <div className="w-full xl:w-[40%] flex flex-col sm:flex-row gap-[30px] justify-start">
          <ul className="flex flex-col gap-[15px]">
            <h3 className="text-white font-semibold text-[18px]">PRODUCTS</h3>
            <Link to="/" className="cursor-pointer">
              AI Resume
            </Link>
            <a
              href="https://recruitcrm.io/jobs/STEM_Solutions_jobs"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <button> Job Matches</button>
            </a>
            <a
              href="https://stemsolutions.net"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <button> Cleared Recruiting</button>
            </a>
          </ul>
          <ul className="flex flex-col gap-[15px]">
            <h3 className="text-white font-semibold text-[18px]">COMPANY</h3>
            <a
              href="https://www.stemsolutions.net/about-us"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <button> About us</button>
            </a>
            <a
              href="https://recruitcrm.io/jobs/STEM_Solutions_jobs"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <button> Careers</button>
            </a>
            <a
              href="/contact"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <button> Contact</button>
            </a>
          </ul>
          <ul className="flex flex-col gap-[15px]">
            <h3 className="text-white font-semibold text-[18px]">LEGAL</h3>
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <button> Privacy</button>
            </a>
            <a
              href="/terms-of-services"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <button> Terms</button>
            </a>
          </ul>
        </div>
      </div>
      <div className="w-full text-[14px] border-t border-[#51635d] pt-[30px]">
        <p>© 2024 ClearanceResume.ai</p>
      </div>
    </div>
  );
};

export default Footer;
