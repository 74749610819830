import { Close } from "feather-icons-react";
import {
  LogIn,
  Paperclip,
  Star,
  Upload,
} from "feather-icons-react/build/IconComponents";
import React from "react";
import img from "../../Assets/clearance/1.png";
import img1 from "../../Assets/logo.jpeg";
import logoDark from "../../Assets/logodark.PNG";

import video from "../../Assets/resume-builder.MOV";
import Accordions from "./Accordion";
import Accordion from "./Accordion";
import TestimonialSlider from "./Slider";
import Header from "../../common/header";
import Footer from "../../common/footer";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import Img2 from "../../Assets/stemsolutions.png";
const Home2 = () => {
  const navigate = useNavigate();
  const PageTransitions = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };
  return (
    <motion.div variants={PageTransitions} initial="hidden" animate="visible">
      <Header />
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="flex flex-col xl:flex-row w-full px-[30px] xl:px-[14%] content-start justify-start border-b-2 border-solid border-gray-200 py-[10vh] gap-2"
      >
        <div className="w-full xl:w-[50%] flex flex-col gap-[15px]">
          <p className="text-[11px] text-center font-semibold bg-green-60 w-80 px-2 border-solid border-2 border-[#3a415c] rounded-[10px] text-[#3a415c] ">
            90% of job seekers recommend ClearanceResume.ai
          </p>
          <h2 className="text-[40px] leading-[1.2em] text-left font-semibold">
            Save time and get more interviews with AI
          </h2>
          <p className="text-[17px] leading-[1.3em] text-left text-gray-500 ">
            The only resume generator created for security clearance holders and
            transitioning military members. Create your FREE resume in minutes!
          </p>
          <div className="flex flex-col sm:flex-row gap-[15px] items-center">
            <input
              type="text"
              className=" w-full xl:w-[60%] p-[12px] rounded-[8px] border-solid border-[1px] border-black"
              placeholder="Enter your Email address"
            />
            <a
              href="https://app.clearanceresume.ai/auth/signup"
              target="_blank"
              rel="noopener noreferrer"
              className="p-[12px] w-full xl:w-[40%] text-[14px] flex justify-center  sm:m-0 bg-[#656f83] top-3 text-white rounded-[10px]"
            >
              <button> Get Started Now</button>
            </a>
          </div>
          <p className="text-[12px] text-gray-700 mt-3 ">
            By clicking Get Started, you agree to our
            <Link to="/terms-of-services">
              <span className="text-[#3a415c] cursor-pointer underline">
                {" "}
                Terms of Service{" "}
              </span>{" "}
            </Link>
            and{" "}
            <Link to="/privacy-policy">
              <span className="text-[#3a415c] underline">Privacy Policy.</span>
            </Link>
          </p>
        </div>
        <div className="w-full xl:w-[50%] align-middle flex justify-center">
          <img src={img} alt="clearance resume" className="text-centre" />
        </div>
      </motion.div>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="px-[30px] xl:px-[14%] py-[6%] bg-[#f8f8f8]"
      >
        <h1 className="text-[30px] sm:text-[40px] text-center font-semibold mb-[40px]">
          ClearanceResume.ai makes creating a resume for security clearance
          holders easy
        </h1>
        <div className="flex gap-[50px] justify-center flex-wrap xl:flex-nowrap">
          <div className="w-full sm:w-[45%] text-center xl:w-[33%] ">
            <div className="p-4 border border-gray-300 rounded-[50%] inline-block shadow-2xl">
              <Upload className="text-5" />
            </div>
            <h3 className="text-[16px]">Free professional resume</h3>
            <p className="pt-[15px] text-[14px] leading-8 text-gray-500">
              Use our AI to create a custom resume in minutes. Our AI is
              optimized to create high qulity resumes for the security cleared
              and military community.
            </p>
          </div>
          <div className="text-center w-full sm:w-[45%] xl:w-[33%]">
            <div className="p-4 border border-gray-300 rounded-[50%] inline-block shadow-2xl">
              <Star />
            </div>
            <h3 className="text-[16px]">Custom Resume for every application</h3>
            <p className="pt-[15px] text-[14px] leading-8  text-gray-500">
              Our AI can customize a resume for free in minutes for every job
              you apply for to help you stand out from the pack.
            </p>
          </div>
          <div className="text-center w-full sm:w-[50%] xl:w-[33%]">
            <div className="p-4 border border-gray-300 rounded-[50%] inline-block shadow-2xl">
              <Paperclip className="text-5" />
            </div>
            <h3 className="text-[16px]">Built For You</h3>
            <p className="pt-[15px] text-[14px] leading-8 text-gray-500">
              Resume generation for security-cleared and military personnel. AI
              tailored for security clearance challenges.
            </p>
          </div>
        </div>

        <motion.div variants={Animation} initial="hidden" whileInView="visible">
          <video
            src={video}
            autoPlay
            loop
            muted
            className="shadow-lg mt-20 rounded-[10px] border-solid border-[0.5px] border-gray-200"
          ></video>
        </motion.div>
        <motion.div
          variants={Animation}
          initial="hidden"
          whileInView="visible"
          className="flex justify-center items-center gap-10 mt-16"
        >
          <img src={logoDark} alt="" className="w-[10%]" />
          <p>Is Proudly Presented By</p>
          <img src={Img2} alt="" className="w-[10%]" />
        </motion.div>

        <Accordions className="mt-[100px]" />
      </motion.div>
      <TestimonialSlider />
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView="visible"
        className="py-[70px] px-[30px] flex flex-col gap-[35px]"
      >
        <h3 className="text-[30px]  font-semibold mb-4">
          Check out these articles on cleared recruiting and job seeking
        </h3>
        <div className="w-full flex flex-wrap gap-[20px] justify-center ">
          <div className="bg-[url('./Assets/service-to-sector.avif')] bg-black relative bg-center w-full xl:w-[47%] bg-cover bg-no-repeat rounded-[10px]  flex flex-col justify-end p-[20px] xl:p-3 text-white h-[300px]">
            <div className="bg-black opacity-[0.4] z-[2] absolute w-full h-full left-0 top-0"></div>
            <h3 className="text-[30px] z-[5]">Service to Sector</h3>

            <a
              href="https://www.thesf86.com/p/service-sector"
              target="_blank"
              rel="noopener noreferrer"
              className="z-[5]"
            >
              <p className="text-[18px] ">Learn More</p>
            </a>
          </div>
          <div className="bg-[url('./Assets/navigating-the-va.avif')] bg-black relative rounded-[10px] bg-center w-full xl:w-[47%] bg-cover bg-no-repeat h-[300px] flex flex-col justify-end p-[20px] xl:p-3 text-white">
            <div className="bg-black opacity-[0.4] z-[2] absolute w-full h-full left-0 top-0"></div>
            <h3 className="text-[30px] z-[5]">Navigating the VA</h3>

            <a
              href="https://www.thesf86.com/p/navigating-va"
              target="_blank"
              rel="noopener noreferrer"
              className="z-[5]"
            >
              <p className="text-[18px] ">Learn More</p>
            </a>
          </div>
          <div className="bg-[url('./Assets/service-to-strategy.avif')] bg-black relative rounded-[10px] bg-center w-full xl:w-[47%] bg-cover bg-no-repeat h-[300px] flex flex-col justify-end p-[20px] xl:p-3 text-white">
            <div className="bg-black opacity-[0.4] z-[2] absolute w-full h-full left-0 top-0"></div>
            <h3 className="text-[30px] z-[5]">Service to Strategy</h3>
            <a
              href="https://www.thesf86.com/p/recruitngveterans"
              target="_blank"
              rel="noopener noreferrer"
              className="z-[5]"
            >
              <p className="text-[18px] ">Learn More</p>
            </a>
          </div>
        </div>
      </motion.div>
      <Footer />
    </motion.div>
  );
};

export default Home2;
